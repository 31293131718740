.route--active {
  // font-weight: bold;
  // text-decoration: underline;
}

.nav.navbar-nav.navbar-right {
  a {
    &:hover {
      color: #d5eaff;
    }
    &:focus {
      color: white;
    }
  }
}

.navbar-main.navbar.navbar-static-top {
  .nav.navbar-nav {
    display: flex;
    float: right;
    position: relative;
    justify-content: flex-end;
    width: 100%;
    padding-left: 80px;
    li.language-switcher {
      height: 80px !important;
      padding: 0px !important;
      button[type="button"], button[type="button"] + div {
        display: none !important;
      }
      >div >div{
        padding: 0 !important;
        height: auto !important;
        div{
          padding: 0 !important;
        }
      }
    }
    .logout-link {
      float: right;
      a {
        color: #666666;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      }
      display: none;
      img {
        height: 17px;
      }
    }
    .header-profile.name {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: 767px) {
        flex-grow: 0;
      }
    }
    @media (min-width: 768px) {
      width: 100%;
      float: left;
      .logout-link {
        display: block;
      }
      li.collapse {
        display: none;
      }
    }
    @media (min-width: 1280px) {
      float: left;
      padding-left: 0;
      .logout-link {
        margin-right: 15px;
      }
    }
    a {
      padding-right: 0;
      font-size: 16px;
      cursor: pointer;
      img {
        height: 36px;
        width: 36px;
        object-fit: contain;
        margin-right: 10px;
      }
      text-decoration: none;
      color: #1aa6b7;
      &:hover {
        color: #1aa6b7;
      }
      &:focus {
        color: #1aa6b7;
      }
    }
    .header-profile {
      img {
        object-fit: cover;
      }
    }
  }
}

.ninjou-logo {
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
  width: 99px;
  background-repeat: no-repeat;
  background-position: 0px 27px;
  background-size: contain;
  a {

  }
}

.container-fluid {
  max-width: 1440px;
  padding-left: 35px !important;
  padding-right: 35px !important;
  @media (min-width: 1280px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.navbar.navbar-static-top {
  .navbar-header {
    float:left;
  }
  .navbar-nav {
    float:right;
    margin: 0;
    margin-right: -15px;
    li {
      float: left;
    }
  }
  li.language-switcher {
    height: 80px !important;
    padding: 0px !important;
    color: white !important;
    button[type="button"], button[type="button"] + div {
      display: none !important;
    }
    >div >div{
      padding: 0 !important;
      height: auto !important;
      div{
        padding: 0 !important;
      }
    }
  }
}

.ninjou-hamb {
  position: absolute;
  height: 30px;
  width: 30px;

  background-repeat: no-repeat;
  background-size: contain;
  left: 10px;
  top: 27px;
}

.menu-close {
  height: 80px;
  display: block;
  background-color: #4c637b;
  background-repeat: no-repeat;
  background-position: 20px 27px;
  a {
    position: absolute;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    right: 10px;
    top: 27px;
  }
}
