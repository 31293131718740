.login-container {
  background-color: white;
  -webkit-box-shadow: 0px 5px 20px 6px rgba(0,0,0,.14);
  -moz-box-shadow: 0px 5px 20px 6px rgba(0,0,0,.14);
  box-shadow: 0px 5px 20px 6px rgba(0,0,0,.14);
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 350px;
  .btn-success {
    height: 50px !important;
  }
}

.content-bg{
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
}
::-webkit-input-placeholder {
   font-size: 18px;
   font-family: "Source Sans Pro", sans-serif !important;
   font-style: italic;
   font-weight: 400;
   color: #1dbdcc !important;
}
.login-form-container {
  margin-top: 113px;
  padding-left: 25px;
  label{
    color: #4c637b;
    font-weight: 600;
  }
  input {
    background-color:#f2f6fa;
    height: 52px;
  }
  @media (max-width: 1200px){
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    a {
      margin-bottom:50px;
    }
  }
  @media (max-width: 767px){
    .form-group {
      margin-bottom: 33px;
    }
  }
}

.medium-margin {
  padding-top: 15px;
  a {
    clear: both;
    font-size: 18px;
    display: block;
    text-decoration: underline;
    @media (min-width: 1280px){
      margin-top: 10px;
    }
  }
}
input:focus::-webkit-input-placeholder {
    color: #888 !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f2f6fa inset;
  -webkit-text-fill-color: #4c637b !important;
}
.btn-success{
  background-color: #67ba2f;
}
.welcome-container {
  min-height: 500px;
  background-color: #4c637b;
  background-size:cover;
  -moz-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  color: white;
  @media (max-width: 1200px){
    min-height:300px;
    border-radius: 5px 5px 0 0;
  }
  h1 {
    font-size: 310%;
    font-weight: 200;
    margin-bottom: 18px;
    letter-spacing: 2px;
  }
  span {
    font-size: 122%;
    font-weight: 100;
    line-height: 16pt;
    letter-spacing: 0.8px;
  }
  .row {
    padding: 75px 120px 0px 50px;
  }
  @media (max-width: 1200px){
    .row{
      padding-top: 25px;
      padding-left: 25%;
      padding-right: 25%;
      text-align: center;
      a{
        display: block;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 767px){
    .row {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}
.welcome-register-container {
  min-height: 500px;
  background-color: #4c637b;
  background-size:cover;
  -moz-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  color: white;
  @media (max-width: 1279px){
    min-height:300px;
    border-radius: 5px 5px 0 0;
  }
  h1 {
    font-size: 310%;
    font-weight: 200;
    margin-bottom: 18px;
    letter-spacing: 2px;
  }
  span {
    font-size: 122%;
    font-weight: 100;
    line-height: 16pt;
    letter-spacing: 0.8px;
  }
  .row {
    padding: 75px 120px 0px 50px;
  }
  @media (max-width: 1279px){
    .row{
      padding-top: 25px;
      padding-left: 25%;
      padding-right: 25%;
      text-align: center;
      a{
        display: block;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 767px){
    .row {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}
.no-padding {
  padding: 0 !important;
}

.register-link {
  height: 50px;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  display: block;
  position: relative;
  margin-top: 30px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  &:hover {
    color: white;
    background: hsla(180,0%,50%,0.25);
  }
}
