.icon-print {
  background-image: url('./assets/print.png');
  background-repeat: no-repeat;
}
.icon-wallet {
  background-image: url('./assets/wallet_yellow.png');
  background-repeat: no-repeat;
}

.btn-group {
  .btn-square {
    border: 1px solid #f2f6fa;
  }
}
.wage-card {
  // margin-top: 15px;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: #f2f6fa;
  -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

  h1 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .card-header {
    height: 40px;
    line-height: 40px;
    background-color: #67ba2f;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .icon-wallet {
    display: block;
    width: 25px;
    height: 25px;
    background-size: 25px;
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .btn-group {
    position: absolute;
    bottom: 20px;
    right: 30px;
  }
  .field {
    display: table-cell;
    padding-right: 10px;
    &.text {
      font-weight: 700;
    }
  }
  .table-row {
    display: table-row;
  }
  .pane {
    position: relative;
    padding: 25px 30px 20px 30px;
  }
}
