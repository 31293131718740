button {
  &:focus {
    outline: none !important;
  }
}
.icon-duplicate {
  background: url('./assets/duplicate.png') no-repeat;
}
.icon-calendar {
  background: url('./assets/calendar.png') no-repeat;
}
.icon-pencil {
  background: url('./assets/pencil.png') no-repeat;
}

.icon-plane {
  background: url('./assets/paperplane.png') no-repeat;
}
.icon-arrow-down {
  background: url('./assets/arrow_down.png') no-repeat;
}

.icon-yes {
  background: url('./assets/yes.png') no-repeat;
}

.icon-no {
  background: url('./assets/no.png') no-repeat;
}

.icon-bell-dark {
  background: url('./assets/bell.png') no-repeat;
}

.btn-square {
  background-color: #e3eaf3;
  border-color: 1px solid #4c6b8a;
}
input.styled-checkbox:disabled + label:after {
  border-color: #bbbbbb;
}
input:disabled,
select:disabled,
.styled-checkbox:disabled + label:before {
  background-color: #f4f4f4 !important;
  cursor: not-allowed;
}
.styled-checkbox:checked:disabled + label:before {
  background-color: #deedd3 !important;
}

.styled-checkbox-text {
  float: left;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.styled-checkbox {
  position: absolute;
  display: none;

  & + label {
    position: relative;
    display: inline;
    cursor: pointer;
    padding: 0;
  }

  //box
  & + label:before {
    content: '';
    display: inline-block;
    margin-top: -6px;
    vertical-align: text-top;
    border: 1px solid #c0cad4;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    background: white;
  }

  &:hover + label:before {
    background: #fafbfc;
  }
  &:checked + label:before {
    background-color: #67ba2f;
    border: none;
    -moz-box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  }
  // mark
  &:checked + label:after {
    content: '';
    position: absolute;
    display: block;
    left: 12px;
    top: 3px;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.new-invoice,
.modal-body,
.modal-footer {
  .form-control,
  .btn {
    height: 50px;
    width: 100%;
    padding: 0;

    &.div-button {
      padding-top: 15px;
    }

    .buttons {
      float: right;
      font-size: 16px;
      font-weight: 600;
      color: #4c637b;
      .btn-group {
        margin-left: 3px;
        margin-top: -7px;
      }
      .btn-square {
        height: 30px;
        width: 30px;
        font-size: 30px;
        line-height: 0;
        border: 1px solid #f2f6fa;
        padding: 0;
        i {
          background-size: 18px;
        }
      }
    }
  }
  input,
  select,
  .form-control {
    color: #1aa6b7;
    background-color: #f2f6fa;
    padding: 0 20px 0 20px;
    line-height: 50px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &.invoice-text {
      line-height: normal;
      padding: 20px;
      height: auto;
      min-height: 100px;
    }
  }
  .col-rights {
    float: right;
  }

  .head-text {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .summary {
    font-size: 25px;
    border-top: 2px solid #1aa6b7;
    border-bottom: 2px solid #1aa6b7;
    margin-top: 40px;
    margin-bottom: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-weight: 100;
    .sum {
      font-weight: 700;
      white-space: nowrap;
    }
  }

  .panel {
    &.info-title {
      display: flex;
      > div {
        padding: 0;
        &.grow {
          flex-grow: 1;
        }
        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
    }
    .status {
      color: #4c637b;
      &.open {
        color: #67ba2f;
      }
      &.closed {
        color: #c13155;
      }
      &.pending {
        color: #e6a722;
      }
      &.aproved {
        color: #1aa6b7;
      }
    }
    .invoice-id {
      color: #1aa6b7;
    }
  }
  @media (max-width: 767px) {
    .btn.btn-success.medium-margin {
      margin-top: 10px;
    }
  }
}
div.btn-success {
  padding: 14px;
  width: 200px;
  margin: 0 10px;
  &.white {
    background-color: white;
    color: #67ba2f;
  }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background: url('./assets/arrow_down.png') no-repeat right #f2f6fa;
  background-size: 10px;
  background-position-x: calc(100% - 20px), calc(100% - 16px), 100%;
}
.btn-accept {
  color: white;
  background-color: #1aa6b7;
  &:hover {
    background-color: #168f9e;
    color: white;
  }
  &:focus {
    background-color: #11727e;
    color: white;
  }
}

.button-add {
  border: 1px solid #4cae4c;
  color: #4cae4c;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    color: #4cae4c;
    border: 1px solid #4cae4c;
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.03);
    color: #4cae4c;
    border: 1px solid #4cae4c;
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.03);
    color: #4cae4c;
    border: 1px solid #4cae4c;
  }
}

.link-add {
  background: none !important;
  background-color: none !important;
  border: none;
  padding: 0 !important;
  color: #4cae4c;
  box-shadow: none !important;
  cursor: pointer;
  &:hover {
    color: darken($color: #4cae4c, $amount: 5) !important;
  }
  &:focus {
    color: darken($color: #4cae4c, $amount: 6) !important;
  }
  &:active {
    color: darken($color: #4cae4c, $amount: 30) !important;
  }
  span {
    padding-left: 5px;
  }
}
.time-to-route {
  @media screen and (min-width: 768px) {
    padding-top: 30px;
  }
}

.green {
  color: #4cae4c;
}

.btn-cancel {
  border: 1px solid #e6a722;
  color: #e6a722;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    color: #e6a722;
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.03);
    color: #e6a722;
  }
}
.empty-label {
  @media (min-width: 720px) {
    margin-top: 24px;
  }
}
.padding-top {
  @media (min-width: 720px) {
    padding-top: 24px;
  }
}

.invoice-row {
  border-radius: 5px;
  .ext-item {
    padding: 0 30px 20px;
  }
  .invoice-row-body {
    margin-bottom: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    form {
      margin-top: 30px;
    }
    > div:last-child {
      margin-bottom: 25px;
    }
    overflow: hidden;
    background-color: #f2f6fa;
    -webkit-transition: max-height ease-in-out 0.3s;
    transition: max-height ease-in-out 0.3s;
  }
  @media (min-width: 1280px) {
    padding-left: 15px;
    padding-right: 15px;
    .title {
      width: 100%;
    }
  }
  .title {
    background-color: #67ba2f;
    color: white;
    cursor: pointer;
    display: table;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    > div {
      display: table-cell;
      vertical-align: middle;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 1;
    }
  }
  .buttons .btn-group .btn.btn-square {
    background: none;
    border: none;
  }
  button.delete {
    font-size: 20px;
    font-weight: 600;
    width: 210px;
    line-height: 40px;
    border: none;
    margin-left: 2px;
    .icon-trash {
      float: right;
      width: 31px;
      background-size: 17px;
      background-position-x: 10.5px;
      top: 0;
      border-radius: 0;
    }
    .icon-plane {
      float: right;
      width: 31px;
      background-size: 17px;
      background-position-x: 10.5px;
      top: 0;
      border-radius: 0;
    }
    .no-border {
      border: 0px;
    }
  }
  .form-control {
    background-color: white;
  }
  .additional {
    > .form-group {
      font-size: 18px;
      border-bottom: 1px solid #cccccc;
      min-height: 55px;
    }
  }
  .chevron {
    width: 20px;
    height: 20px;
    display: block;
    background-position: center;
    background-size: 100%;
    background-image: url('./assets/chevron-white.png');
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &.collapsed {
    .title {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .invoice-row-body {
      max-height: 0px;
    }
    .chevron {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}
.DayPickerInput {
  display: block !important;
  .DayPickerInput-OverlayWrapper {
    z-index: 100;
  }
}

.small-summary {
  font: small;
  font-size: 12px;
  margin-top: 25px;
}

.checkbox-container {
  margin-top: 12px;
  height: 50px !important;
}

.a-button {
  margin-top: 15px;
}

.waypoint-button-container {
  width: 10% !important;
  float: left;
  .remove-waypoint {
    background-color: #1aa6b7;
    mask-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='iso-8859-1'?><svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'	 viewBox='0 0 27.965 27.965' style='enable-background:new 0 0 27.965 27.965;' xml:space='preserve'><g>	<g id='c142_x'>		<path class='remove-icon' d='M13.98,0C6.259,0,0,6.261,0,13.983c0,7.721,6.259,13.982,13.98,13.982c7.725,0,13.985-6.262,13.985-13.982			C27.965,6.261,21.705,0,13.98,0z M19.992,17.769l-2.227,2.224c0,0-3.523-3.78-3.786-3.78c-0.259,0-3.783,3.78-3.783,3.78			l-2.228-2.224c0,0,3.784-3.472,3.784-3.781c0-0.314-3.784-3.787-3.784-3.787l2.228-2.229c0,0,3.553,3.782,3.783,3.782			c0.232,0,3.786-3.782,3.786-3.782l2.227,2.229c0,0-3.785,3.523-3.785,3.787C16.207,14.239,19.992,17.769,19.992,17.769z'/>	</g>	<g id='Capa_1_104_'>	</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    border: 0px;
    box-shadow: none;
    background-size: 80%;
    background-repeat: no-repeat;
    margin-top: 10px;
    height: 30px;
    &:hover {
      background-color: darken($color: #1aa6b7, $amount: 3);
    }
  }
  i {
    color: red;
  }
}

.remove-icon {
  fill: orange;
}
.waypoint-input {
  width: 65% !important;
  float: left;
}
.waypoints {
  width: 100%;
  position: relative;
  margin-top: 20px;
  display:inline-block;
  .waypoint-item-container {
    margin-top: 10px;
    .waypoint-item {
      float: left;
      width: 100%;
    }
  }
}
