.calculator {
  .tabs {
    width: 100%;
    .col-xs-6 {
      max-width: 210px;;
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }

  }
  button {
    height: 50px;
    width: 100%;
  }
  .form-control {
    color: #1aa6b7;
    background-color: #f2f6fa;
    padding:0 20px 0 20px;
    height: 50px;
    line-height: 50px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .toggle-additional {
    .additional-label {
      font-size: 18px;
      margin-left: 15px;
      margin-top: 40px;
      margin-bottom: 30px;
      span {
        background-image: linear-gradient(to right, #a5b1bd 50%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x;
        vertical-align: middle;
        font-weight: 300;
      }
      cursor: pointer;
      div:before {
        font-size: 30px;
        vertical-align: middle;
        font-weight: 400;
        content: '+ ';
      }
    }
    .additional {
      max-height: 0px;
      overflow: hidden;
      -webkit-transition: max-height ease-in-out .3s;
      transition: max-height ease-in-out .3s;
    }
    &.opened {
      .additional-label{
        div:before {
          content: '– ';
        }
      }
      .additional {
        max-height: 900px;;
      }
    }
  }
  .summary {
    font-size: 18px;
    border-top: 2px solid #e3eaf3;
    border-bottom: 2px solid #e3eaf3;
    margin-top: 40px;
    margin-bottom: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-weight: 100;
    .sum {
      font-size: 24px;
      font-weight: 700;
      color: #1aa6b7;
    }
  }
}
.calculator-result {
  margin-top: 50px;
  table {
    width: 100%
  }
  font-size: 18px;
  .sum {
    font-size: 24px;
    font-weight: 700;
  }
}
