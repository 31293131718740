.description {
  margin-top: 30px;
  margin-bottom: 30px;
}
.icon-trash {
  background-image: url('./assets/trash.png');
  background-repeat: no-repeat;
}

.table {
  color: #1aa6b7;
  font-size: 18px;
  width: 100%;
  display: table;
  padding-left: 15px;
  padding-right: 15px;
  .table-header{
    font-weight: 600;
    text-transform: uppercase;
  }
  .table-row {
    height: 70px;
    line-height: 70px;
    background-color: #f2f6fa;
    &:nth-child(odd){
      background-color: #ebf1f5;
    }
  }
  .table-header {
    color: #4c637b;
    height: 50px;
    line-height: 50px;
    background-color: #e3eaf3;
  }
  .table-row, .table-header {
    display: table-row;
    .field {
      display: table-cell;
      padding-left: 30px;
      padding-right: 30px;
      .no-padding {
        padding-left: 0px;
      }
      &:first-child{
        padding-right: 20px;
      }
      &.middle {
        vertical-align: middle;
      }
      &:not(.middle) {
        line-height: 1;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      @media (max-width: 767px) {
        padding-left: 10px;
        padding-right: 10px;
        &:first-child{
          padding-left: 20px;
          padding-right: 0px;
        }
        &:last-child{
          padding-right: 20px;
          padding-left: 0px;
        }
      }
    }
    &:first-child{
      div:first-child {
        border-top-left-radius: 5px;
      }
      div:last-child {
        border-top-right-radius: 5px;
      }
    }
    &:last-child{
      div:first-child {
        border-bottom-left-radius: 5px;
      }
      div:last-child {
        border-bottom-right-radius: 5px;
      }
      -webkit-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1);
    }
  }
}
