.error-alert {
    margin: auto;
    border: 3px solid rgb(255, 94, 0);
    background-color: rgb(255, 102, 0);
    padding: 10px;
    text-align: center;
    color: white;
    font-size: large;
    font-weight: bolder;
    border-radius: 5px;
    margin-bottom: 10px;
}

.error-alert a {
    color: white;
    text-decoration: underline;
}