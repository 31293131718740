.customers {
  .btn-success,
  .search {
    width: 100%;
    border: none;
  }
  .modal {
    button {
      width: auto;
    }
  }
  .businessId {
    width: 95px;
  }
  .control-buttons {
    width: 90px;
  }
  .table {
    font-size: 18px;
    .table-header,
    .field-name {
      font-weight: 600;
      color: #4c637b;
      text-transform: uppercase;
    }
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1280px) {
      width: 100%;
      display: table;
      padding-left: 15px;
      padding-right: 15px;
      .client {
        background-color: #f2f6fa;
        &:nth-child(odd) {
          background-color: #ebf1f5;
        }
        .field {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      .table-header {
        background-color: #e3eaf3;
      }
      .btn {
        // margin-top: 15px;
      }
      .client,
      .table-header {
        display: table-row;
        .field {
          display: table-cell;
          &:first-child {
            padding-left: 30px;
            padding-right: 0px;
          }
          &:nth-last-child(2) {
            padding-left: 0px;
            padding-right: 10px;
          }
        }
        .field-name {
          display: none;
        }
        &:first-child {
          div:first-child {
            border-top-left-radius: 5px;
          }
          div:last-child {
            border-top-right-radius: 5px;
          }
        }
        &:last-child {
          div:first-child {
            border-bottom-left-radius: 5px;
          }
          div:last-child {
            border-bottom-right-radius: 5px;
          }
          -webkit-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .client {
      width: 100%;
      @media (min-width: 768px) and (max-width: 1279px) {
        display: table-cell;
        float: left;
        width: 50%;
      }
      @media (max-width: 1279px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
        .field:nth-child(even) {
          background-color: #e3eaf3;
        }
        .field:nth-child(odd) {
          background-color: #f2f6fa;
        }
        float: left;
        .field {
          float: left;
          width: 100%;
          padding: 20px;
          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
          &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            -webkit-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.27);
            -moz-box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.27);
            box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.27);
          }
        }
        .field-name,
        .field-text {
          float: left;
        }
        .field-name {
          width: 45%;
        }
        .field-text {
          width: 55%;
        }
      }
    }
  }
}

.margin-top-bottom {
  margin-top: 50px;
  margin-bottom: 50px;
}
