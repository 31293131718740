.register-container {
  -webkit-box-shadow: 0px 5px 20px 6px rgba(0,0,0,.14);
  -moz-box-shadow: 0px 5px 20px 6px rgba(0,0,0,.14);
  box-shadow: 0px 5px 20px 6px rgba(0,0,0,.14);
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 800px;
  background-color: white;
  @media (min-width: 1280px){
    display: flex;
    .btn-success {
      margin-bottom: 50px;
    }
  }
  form{
    margin-bottom:35px;
  }
}
::-webkit-input-placeholder {
   font-size: 18px;
   font-family: "Source Sans Pro", sans-serif !important;
   font-style: italic;
   font-weight: 400;
   color: #1dbdcc !important;
}
.register-form-container {
  margin-top: 50px;
  padding-left: 25px;
  margin-bottom: 40px;
  label{
    color: #4c637b;
    font-weight: 600;
  }
  input {
    background-color:#f2f6fa;
    height: 52px;
  }
  @media (max-width: 1279px){
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    a {
      margin-bottom: 50px;
    }
  }
  @media (max-width: 767px){
    .form-group {
      margin-bottom: 33px;
    }
  }
}
