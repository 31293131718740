.footer{
  line-height: 50px;
  list-style: none;
  position: relative;
  height: 60px;
  border: none;
  border-radius: 0px;
  background-color: #e3eaf3;
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .footer-left{
    margin-left: -15px;
    float: left;
    a{
        font-weight: 600;
        text-decoration: none;
    }
  }
  .footer-right{
    margin-right: -15px;
    float: right;
    li{
      float:left;
    }
  }

  a{
    display: block;
    position: relative;
    padding: 15px;
    height: 60px;
    font-weight: 400;
    text-decoration: underline;
    padding-top: 6px !important;
    color: #1aa6b7 !important;
    &:hover{
      color: #105d66 !important;
    }
  }
  @media (max-width: 480px){
    .container-fluid{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
