.landing {
  .wrap {
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .container {
    margin: 0 auto;
    padding: 0;
    max-width: 1170px;
    @media (min-width: 768px){
      width: auto;
    }
  }
  >.header {
    color:white;
    background: url('../assets/header-bg.jpg');
    background-position: 50%;
    background-size: cover;
    padding-bottom: 30px;
    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .head {
      margin-bottom: 40px;
      margin-top: 30px;
      @media (min-width: 768px) {
        margin-top: 48px;
      }
      @media (min-width: 1280px) {
        margin-top: 55px;
      }
      .buttons {
        width: fit-content;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 768px) {
          margin-left: 0;
          margin-right: 0;
          float: right;
        }
        @media (min-width: 1280px) {
          margin-bottom: 70px;
        }
        > a {
          &:first-child {
            margin-right: 20px;
          }
        }
      }
      .logo {
        width: 150px;
        height: 54px;
        margin-left: auto;
        margin-right: auto;
        background: url('../assets/logo.png') no-repeat;
        background-position: 50%;
        background-size: contain;
        @media (min-width: 768px) {
          float: left;
        }
      }
    }
    .banner-info {
      text-transform: uppercase;
      border: 3px solid #aa7142;
      background-color: rgba(26, 166, 183, .3);
      padding: 35px;
      text-align: center;
      font-size: 30px;
      font-weight: 200;
      max-width: 950px;
      margin: 0 auto;
      line-height: 1;
      @media (min-width: 768px) {
        font-size: 48px;
      }
      @media (min-width: 1280px) {
        font-size: 60px;
      }
    }
    .header-hint {
      text-align: center;
      font-style: italic;
      font-weight: 400;
      margin: 0 auto;
      font-size: 18px;
      margin-bottom: 30px;
      width: 65%;
      max-width: 500px;
      @media (min-width: 768px) {
        font-size: 18px;
      }
      @media (min-width: 1280px) {
        margin-bottom: 80px;
      }
    }
    .hr {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      border-bottom: 1px solid white;
      width: 70%;
      max-width: 450px;
      @media (min-width: 768px) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @media (min-width: 1280px) {
      }
    }
  }
  button {
    border-radius: 0;
    border:none;
    -webkit-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -ms-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    &.btn {
      width: 200px;
      height: 50px;
      font-size: 18px;
      color: white;
      background-color: #1aa6b7;
      border: none;
      &.transparent {
        border: 1px solid white;
        background-color: transparent;
      }
    }
    &.mouse {
      display: none;
      margin: 0 auto;
      background: url('../assets/mouse.png') no-repeat;
      background-size: contain;
      width: 20px;
      height: 60px;
      @media (min-width: 768px) {
        display: block;
      }
    }
    &:hover {
      opacity: .7 !important;
    }
  }
  .heading {
    width: 300px;
    height: 70px;
    border: 1px solid #aa7142;
    margin: 0 auto;
    margin-top: 70px;
    font-size: 20px;
    font-weight: 600;
    color: #1aa6b7;
    display: grid;
    text-transform: uppercase;
    position: relative;
    span {
      position: relative;
      line-height: 70px;
      text-align: center;
      z-index: 5;
    }
    &:before {
      content: '';
      z-index: 2;
      width: 60px;
      height: 60px;
      padding: 12px;
      display: block;
      position: absolute;
      margin-left:-30px;
      left: 50%;
      top: -30px;
      background-color: white;
      background-image: url('../assets/star.png');
      background-size: 38px;
      background-position: 50%;
      background-repeat: no-repeat;
    }
    &:after {
      content: '';
      width: 28px;
      height: 28px;
      display: block;
      position: absolute;
      margin-left:-14px;
      bottom: -14px;
      left: 50%;
      background-color: white;
      background-size: 12px;
      background-image: url('../assets/rhombus.png');
      background-position: 50%;
      background-repeat: no-repeat;
    }
    &.star {
      &:before {
        background-image: url('../assets/star.png');
      }
    }
    &.question {
      &:before {
        background-image: url('../assets/question.png');
      }
    }
    &.gear {
      &:before {
        background-image: url('../assets/gear.png');
      }
    }
    &.heart {
      &:before {
        background-image: url('../assets/heart.png');
      }
    }
    &.letter {
      &:before {
        background-image: url('../assets/letter.png');
      }
    }
  }
  .header-hr {
    margin: 15px auto;
    width: 12px;
    height: 12px;
    background-image: url('../assets/rhombus.png');
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    &.white {
      background-image: url('../assets/rhombus-white.png');
      &:before, &:after {
        border-bottom: 1px solid white;
      }
    }
    &.short {
      &:before, &:after {
        width: 85px;
      }
      &:before {
        left: -92px;
      }
      &:after {
        right: -92px;
      }
    }
    position: relative;
    clear: both;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      width: 150px;
      left: -157px;
      border-bottom: 1px solid #aa7142;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      width: 150px;
      right: -157px;
      border-bottom: 1px solid #aa7142;
    }
  }
  .header-text {
    color: #000;
    font-size: 15pt;
    font-weight: 600;
    position: relative;
    margin-top: 15px;
    text-transform: uppercase;
    span {
      display: block;
      text-align: center;
    }
  }
  .plain-text {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 560px;
    text-align: center;
    font-size: 18px;
    @media (min-width: 768px) {
      margin-bottom: 20px;
    }
    @media (min-width: 1280px) {
      margin-bottom: 20px;
      max-width: 810px;
    }
  }

  @media (min-width: 1280px) {
    .info-blocks .row {
      display: -ms-flexbox;
      display: flex;
      .col-md-4 {
        display: -ms-flexbox;
        display: flex;
      }
    }
  }
  .info-block {
    max-width: 490px;
    height: 300px;
    margin: 0 auto;
    margin-top: 80px;
    padding: 30px;
    padding-top: 40px;
    border: 1px solid #aa7142;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 200;
    color: #666666;
    .header-text {
      margin-left: -30px;
      margin-right: -30px;
    }
    br {
       line-height: 22px;
    }
    .text {
      word-break: break-word;
    }
    @media (min-width: 1280px) {
      height: auto;
    }
    @media (min-width: 1680px) {
      margin-bottom: 20px;
    }
    &:before {
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      position: absolute;
      margin-left: -50px;
      top: -50px;
      left: 50%;
      background-color: white;
      border: 1px solid #aa7142;
    }
    &:after {
      content: '';
      display: block;
      width: 80px;
      height: 80px;
      position: absolute;
      margin-left: -40px;
      top: -40px;
      left: 50%;
      background-color: rgba(26, 166, 183, .2);
      background-size: 40px;
      background-position: 50%;
      background-repeat: no-repeat;
    }
    &.suitcase {
      &:after {
        background-image: url('../assets/suitcase.png');
      }
    }
    &.umbrella {
      &:after {
        background-image: url('../assets/umbrella.png');
      }
    }
    &.wallet {
      &:after {
        background-image: url('../assets/wallet.png');
      }
    }
  }
  .questions {
    margin-top: 30px;
    .question {
      padding: 0 10px;
      border-bottom: thin solid #bbb;
      overflow: hidden;
      position: relative;
      .header {
        font-weight: 600;
        font-size: 18px;
        @media (max-width: 767px) {
          font-size: 18px;
        }
        text-transform: uppercase;
        cursor: pointer;
        line-height: 50px;
        -webkit-user-select: none; /* Chrome/Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
      }
      .answer {
        // min-height: 0;
        display: none;
        padding-bottom: 15px;
        // -webkit-transition: max-height cubic-bezier(0, 1, 0, 1) .3s;
        // transition: max-height cubic-bezier(0, 1, 0, 1) .3s;
      }
      .chevron {
        position: absolute;
        right: 0;
        top: 15px;
        width: 15px;
        height: 15px;
        background: url('../assets/chevron.png') no-repeat;
        background-size: 15px;
        -webkit-transition-duration: .3s;
      }
      &.opened {
        .answer {
          // min-height: 500px;
          display: block;
          // -webkit-transition: max-height .3s ease-in-out;
          // transition: max-height .3s ease-in-out;
        }
        .chevron {
          transform: rotate(180deg);
        }
      }
    }
  }
  .interested, .video {
    min-height: 500px;
    background-image: url('../assets/interested-bg.jpg');
    &.video {
      background-image: url('../assets/video-bg.jpg');
    }
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    text-align: center;
    margin-top: 100px;
    padding: 50px 0px;
    color: white;
    .wrap {
      max-width: 720px;
      margin: 0 auto;
      padding: 0;
      @media (min-width: 1280px) {
        max-width: 810px;
      }
    }
    .header {
      font-size: 48px;
      font-weight: 200;
      text-transform: uppercase;
      line-height: .8;
    }
    @media (min-width: 1280px) {
      padding: 110px 24px;
      min-height: 650px;
      .header {
        font-size: 55px;
      }
    }
    .header-hr {
      margin-bottom: 0px;
      @media (min-width: 768px) {
        margin-bottom: 20px;
      }
      @media (min-width: 1280px) {
        margin-bottom: 40px;
      }

    }
    .pane {
      width: 360px;
      margin: 0 auto;
      @media (min-width: 768px) {
        float: left;
        &:last-child {
          float:right;
        }
      }
      .rhombus {
        display: block;
        width: 120px;
        height: 120px;
        margin: 50px auto;
        border: thin solid white;
        transform: rotate(45deg);
        padding: 18px;
        @media (min-width: 1280px) {
          width: 150px;
          height: 150px;
          padding: 22px;
        }
        .inner {
          width: 100%;
          height: 100%;
          background-color: #aa7142;
          &:before {
            content: '';
            display: block;
            transform: rotate(-45deg);
            width: 100%;
            height: 100%;
            background-size: auto 50px;
            @media (min-width: 1280px) {
              background-size: auto 60px;
            }
            background-position: center;
            background-repeat: no-repeat;
          }
          &.lock {
            &:before {
              background-image: url('../assets/lock.png');
            }
          }
          &.user {
            &:before {
              background-image: url('../assets/user.png');
            }
          }
        }
      }
      span {
        font-size: 18px;
      }
      .btn {
        margin-top: 20px;
      }
    }
  }
  .steps {
    position: relative;
    padding: 10px 0;
    margin-left: 15px;
    @media (min-width: 768px) {
      margin-left: 0;
    }
    @media (min-width: 1280px) {
      padding: 40px 0;
    }
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      border-right: thin solid #aa7142;
      @media (min-width: 768px) {
        left: 50%;
      }
    }
    .step {
      display: block;
      position: relative;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      padding-left: 35px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50px;
        left: 15px;
        width: 20px;
        border-bottom: thin solid #aa7142;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 40px;
        left: -10px;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #aa7142;
        border: 4px solid white;
        outline: 1px solid #aa7142;
      }
      @media (min-width: 768px) {
        width: 50%;
        float: left;
        margin-bottom: -35px;
        &:nth-child(odd) {
          padding-right: 35px;
          padding-left: 0;
          &:before {
            right: 15px;
            left: auto
          }
          &:after {
            left: auto;
            right: -10px;
          }
        }
        &:nth-child(even) {
          padding-top: 70px;
          padding-left: 35px;
          &:before {
            top: 120px;
            right:auto;
            left:15px;
          }
          &:after {
            top: 110px;
          }
        }
      }
      .content {
        border: thin solid #aa7142;
        width: 100%;
        height: 100px;
        padding: 20px;
        padding-left: 100px;
        position: relative;
        align-items: center;
        display: flex;
        font-size: 16px;
        @media (min-width: 1280px) {
          font-size: 18px;
        }
        &:before {
          content: '';
          position: absolute;
          margin: 20px;
          width: 60px;
          height: 60px;
          top: 0;
          left: 0;
          background-color: rgba(26, 166, 183, .2);
          background-size: 35px 35px;
          background-position: 50%;
          background-repeat: no-repeat;
        }
        &.star:before {
          background-image: url('../assets/star.png');
        }
        &.key:before {
          background-image: url('../assets/key.png');
        }
        &.document:before {
          background-image: url('../assets/document_plus.png');
        }
        &.euro:before {
          background-image: url('../assets/euro.png');
        }
      }
    }
  }
  .video {
    .play-button {
      margin: 0 auto;
      margin-top: 35px;
      width: 150px;
      height: 200px;
      background: url('../assets/play.png') no-repeat;
      background-position: top;
      background-size: 150px ;
      padding-top: 170px;
      font-size: 30px;
      font-weight: 200;
      -webkit-user-select: none; /* Chrome/Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+ */
      @media (min-width: 1280px) {
        width: 200px;
        height: 260px;
        background-size: 200px ;
        padding-top: 220px;
      }
      span {
        border-bottom: thin solid white;
      }
    }
  }
  .team {
    margin-top: 30px;
    font-size: 18px;
    color: black;
    font-weight: 400;
    text-align: center;
    .person {
      margin-top: 20px;
      .description {
        margin: 0 auto;
        width: 360px;
      }
    }
    .photo {
      height: 270px;
      padding: 20px;
      max-width: 570px;
      outline: 1px solid rgba(26, 166, 183, .5);
      margin: 0 auto;
      -webkit-transition: outline-offset .2s ease-in-out;
      -moz-transition: outline-offset .2s ease-in-out;
      -ms-transition: outline-offset .2s ease-in-out;
      -o-transition: outline-offset .2s ease-in-out;
      transition: outline-offset .2s ease-in-out;
      &:hover {
        outline-offset: 10px;
      }
      img {
        width: 100%;
        height: 100%;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .map {
    background-image: url('../assets/map.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    margin-top: 30px;
    @media (max-width: 1279px) {
      min-height: 750px;
      background-image: url('../assets/map-small.jpg');
    }
    @media (max-width: 767px) {
      min-height: auto;
      background: none;
    }

    .container {
      position: relative;
    }
    .marker {
      display: block;
      position: relative;
      margin: 0 auto;
      // height: 100px;
      padding: 20px;
      font-size: 18px;
      max-width: 570px;
      line-height: 1.2;
      background-color: rgba(255, 255, 255, .9);
      border: 1px solid #aa7142;
      padding-left: 100px;
      &:before {
        content: '';
        position: absolute;
        margin: 20px;
        width: 60px;
        height: 60px;
        top: 0;
        left: 0;
        background-color: rgba(26, 166, 183, .2);
        background-size: 35px 35px;
        background-position: 50%;
        background-image: url('../assets/marker.png');
        background-repeat: no-repeat;
      }
      .description {
        // height: 100px;
        margin: 0;
      }
      @media (min-width: 768px) {
        border: none;
        width: 490px;
        .point {
          position: absolute;
          bottom: -45px;
          right: 0;
          border-style: solid;
          border-width: 0 30px 45px 0;
          border-color: transparent rgba(255, 255, 255, .9) transparent transparent;
        }
      }

    }
    @media (min-width: 1280px) {
      .marker {
        display: block;
        position: absolute;
        right: 0;
        top: 45%;
        .description {
          background-color: rgba(255, 255, 255, .9);
          margin: 0;
          height: 80px;
        }
        .point {
          display: none;
        }
      }
    }
    .contact-form {
      margin-top: 30px;
      margin-bottom: 45px;
      max-width: 570px;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
      border: thin solid #aa7142;
      @media (min-width: 768px) {
        margin-top: 80px;
      }
      @media (min-width: 1280px) {
        border: none;
        padding: 30px;
        margin-left: 0;
      }
      background-color: rgba(255, 255, 255, .9);
      .header-text {
        margin-top: 0;
        color: #aa7142;
        font-size: 20px;
        font-weight: 700;
      }
      .header-hr {
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .form-group {
        margin-bottom: 10px;
      }
      input, textarea {
        background-color: white;
        border-radius: 0;
        border-color: #aa7142;
        font-size: 18px;
      }
      textarea {
        min-height: 100px;
        min-width: 100%;
        max-width: 100%;
      }
      ::-webkit-input-placeholder {
         font-size: 18px;
         font-family: "Source Sans Pro", sans-serif !important;
         font-style: normal;
         font-weight: 400;
         color: #999 !important;
      }
      .credit {
         width: 80px;
         height: 50px;
         background: url('../assets/credit.png') no-repeat;
         background-position: 50%;
         background-size: contain;
      }
    }
  }
  .landing-footer {
    border-top: 3px solid #1aa6b7;
    color: #1aa6b7;
    @media (max-width: 1279px) {
      padding: 35px 0;
    }
    a {
      color: #1aa6b7;
      border-bottom: 1px solid #1aa6b7;
      &:hover {
        text-decoration: none;
        color: #0c5058;
      }
    }
    .left, .right {
      margin: 0 auto;
      display: table;
      padding: 0;
    }
    @media (min-width: 1280px) {
      .left {
        float: left;
      }
      .right {
        float: right;
      }
    }
    ul {
      line-height: 100px;
      font-size: 18px;
      list-style: none;
      @media (max-width: 1279px) {
        clear: both;
        line-height: 50px;
      }
      li {
        margin-left: 15px;
        margin-right: 15px;
        float: left;
      }
    }
  }
}
