button.btn.white {
  background-color: white;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.03);
  }
  &.btn-success {
    color: #67ba2f;
  }
}
.icon-plus {
  background-image: url('./assets/icon-plus.png');
  background-repeat: no-repeat;
}

.icon-plus-white {
  background-image: url('./assets/icon-plus-white.png');
  background-repeat: no-repeat;
}
.btn {
  .icon-plus-white, .icon-plus {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 7px;
  }
}
.account {
  button {
    height: 50px;
    width: 200px;
    &.btn-success{
      margin-top: 35px;
      margin-bottom: 35px;
    }
    &.white {
      margin-right: 20px;
    }
  }
  .form-control {
    color: #1aa6b7;
    background-color: #f2f6fa;
    padding:0 20px 0 20px;
    height: 50px;
    line-height: 50px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .btn-square {
    border: 1px solid #f2f6fa;
  }
  .pic {
    .avatar {
      border-radius:50%;
      width: 150px;
      height: 150px;
      background-color: #f2f6fa;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
    }
    display: flex;
    align-items: center;
    .controls {
      margin-left: 30px;
      text-align: center;
      margin-top: 0;
    }
    margin-bottom: 30px;
    @media (min-width: 1280px) {
      display: block;
      width: 150px;

      .controls {
        margin-left: 0;
        margin-top: 20px;
      }
    }

  }
}

.icon-camera {
  background-image: url('./assets/camera.png');
  background-repeat: no-repeat;
}
.center {
  text-align: center;
}
