.marketing-container {
    margin: 20px 0;
    background-color: white;
    min-height: 200px;
    color: #516379;
    border-radius: 2px;
    text-align: left;
    ul {
      li {
        margin-bottom: 3px;
      }
    }
    h2 {
      color: #516379;
      padding: 15px 15px 0;
    }
    p {
      padding: 0px 15px 0;
    }
    .info-icon {
      text-align:center;
      width: 40px;
      height: 40px;
      background-color: orange;
      float: left;
      border-radius: 6px;
      margin: 18px;
      svg {
        margin-top: 10px;
      }
    }
  }