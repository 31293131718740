.tabs {
  width: 300px;
  margin-top: 20px;
  margin-bottom: 15px;
  .row {
    margin-left: 40px;
    margin-right: 0px;
    border-bottom: 1px solid #d4d4d4;
  }
  .tab {
    float: left;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .65px;
    padding-bottom: 10px;
    &:hover {
      border-bottom: 4px solid rgba(0, 0, 0, 0.18);
    }
    &.active {
      border-bottom: 4px solid;
      font-weight: 600;
      letter-spacing: 0;
    }
  }
}
