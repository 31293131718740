button {
  .icon-plus {
    height: 14px;
    width: 14px;
    background-position: center;
    margin-right: 7px;
  }
  &.btn-new-invoice {
    margin-top: 5px;
    margin-left: 15px;
    height: 50px;
    width: 210px;
  }
}

.no-value {
  border: 1px solid #E6A722;
}