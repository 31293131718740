.duck {
  display: block;
  width: 120px;
  margin: 1.5rem auto;
}

.panel{
  color: #666666;
  margin-bottom: 15px;
  padding-bottom: 15px;
  &.info-title{
    font-size: 25px;
    font-weight: 100;
  }
}
.field-error {
  position: absolute;
  bottom: -15px;
  padding: 0 20px;
  background-color: #edd;
  right: 0px;
}
.form-group {
  position: relative;
}
.no-padding-right {
  padding-right: 0px;
}

.dashboard {
  margin: 0 auto;
  max-width: 1400px;
  @media (max-width: 1279px){
    padding-top: 30px;
  }
}

.chart-card {
  margin-top: 30px;
}
