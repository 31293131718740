.icon-search {
  background-image: url('../assets/search.png');
}

.archive-controls{
  @media (max-width: 767px){
    margin-top: 30px;
  }
}

.order-select {
  border: none;
  background: none;
  font-size: 15px;
  height: 40px;
  font-weight: 700;
  color: #4c637b;

  &:focus {
    outline: none;
  }
  @media (min-width: 768px) {
    float: right;
  }
}
.search {
  input {
    font-size: 15px;
    padding-left: 30px;
    height: 40px;
    color: #4c637b;
    background-color: #e3eaf3;
    box-shadow: none;
    border: none;
  }
  .icon-search {
    height: 18px;
    width: 18px;
  }
  .btn {
    border: none;
    height: 40px;
    border-left: none;
    background-color: #e3eaf3;
  }
}
.button-container {
  margin-top: 25px;
}