.blue {
  color: #1aa6b7;
}
.dkblue {
  color: #4c637b;
}
.dark {
  color: #666666;
}

.icon-eye{
  background-image: url('./assets/eye.png');
  background-repeat: no-repeat;
  // image-rendering: auto;
}
.btn.btn-square {
  width: 40px;
  height: 40px;
  i {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: contain;
  }
  padding-left: 11px;
  padding-right: 11px;
  background-color: #e3eaf3;
  &:focus {
    outline:none;
  }
}

.details {
  table.client-details {
    thead {
      color: #67ba2f;
      font-size: 18px;
      &:first-child tr td {
        padding-top: 0;
      }
      td {
        padding-top: 40px;
        padding-bottom: 20px;
      }
    }
    tbody td {
      line-height: 2;
      font-size: 18px;
      padding-left: 20px;
      &:first-child {
        padding-left: 0px;
        font-weight: 700;
      }
      @media (min-width: 768px){
        line-height: 1.5;
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          font-size: 18px;
        }
      }
    }
  }
}



@media screen and (max-width: 790px) {
  .hide-mobile {
    display: none;
  }
}
