.Select--single {
  .Select-control .Select-value,
  .Select-placeholder {
    padding-left: 20px;
    color: #1aa6b7;
    line-height: 28px;
  }
  .Select-multi-value-wrapper {
    .Select-placeholder {
      padding-top: 7px;
    }
  }
  .Select-control {
    height: 48px;
    background: #f2f7fb;
    .Select-input {
      height: 48px;
      padding-left: 20px;
      input {
        background-color: transparent;
        padding-left: 0;
      }
    }
  }
}
.has-value.Select--single > .Select-control .Select-value .Select-value-label {
  color: #4c637b;
  font-weight: 600;
  line-height: 30px;
}
.Select-menu-outer {
  .VirtualizedSelectOption {
    color: #4c637b;
    padding-left: 2em;
    cursor: pointer;
  }
  .VirtualizedSelectOption.VirtualizedSelectDisabledOption {
    cursor: default;
    padding-left: 1em;
    opacity: 1;
    font-weight: bold;
    background-color: #eee;
  }
}

.Select {
  &.is-open {
    z-index: 1000;
  }
}

.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
  color: #1aa6b7 !important;
}

.Select--single > .Select-control .Select-value {
  margin: 8px !important;
}
.Select-clear {
  font-size: 26px !important;
}
