button {
    font-size: 20px !important;
}


/*
*, div, body, html {
    font-size: 18px !important;
}
*/
