@media (max-width: 600px){
  .container {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
button.bill-row-button {
  max-width: 95px !important;
  height: 30px !important;
}

.margin-right-20 {
  margin-right: 20px;
}

.scaled  {
  scale: 1.3;
}

.DayPickerInput {
  display: block !important;
  .DayPickerInput-OverlayWrapper {
    z-index: 100;
  }
}
/*
.modal-dialog {
  .DayPicker {
    display: block;
    outline: none;
    color: #4c637b;
    border: 1px solid #ccc;
    border-radius: 5px;

    * {
      outline: none
    }
  }
   .DayPicker-Day {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    font-size: 12px;
    cursor: pointer;
  }
  .DayPicker-NavBar {
    height: 70px;
    background-color: rgba(96, 121, 145, 0.13);
    top: 0;
    padding-top: 1rem;
    border-bottom: 1px solid #ccc;
  }
  .DayPicker-Weekdays {
    margin-bottom: 20px;
  }
  .DayPicker-Weekday {
    color: #4c637b;
    width: 30px;
  }
  .DayPicker-Body {
    display: grid;
    min-height: 180px
  }
  .DayPicker-NavButton {
    width: 2.5rem;
    top: 2rem;
    height: 1.8rem;
    background-size: cover;
    &.DayPicker-NavButton--prev{
      left: 2rem;
    }
    &.DayPicker-NavButton--next{
      right: 2rem;
    }
  }
  .DayPicker-Caption {
    font-weight: 600;
  }
  .DayPicker-Month {
    width: 100%;
    justify-content: center;
    display: grid;
    background-color: #f2f6fa;
    padding-bottom: 20px;
    padding-top: 10px;
    margin: 0;
    border-radius: 5px;
  }
  .DayPicker-wrapper {
    padding: 0;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #1aa6b7;
    border-radius: 50%;
  }
  .DayPicker-Weekday abbr {
    text-decoration: none;
    border-bottom: none;
  }
  .DayPicker-Caption {
    height: 30px;
  }
}
*/