.status-item {
  color: #666666;
  padding-bottom:40px;
  margin-bottom: 20px;
  @media (max-width: 1279px){
    padding-bottom: 0px;
    margin-bottom: 30px;
    margin-right: 15px;
  }
  .header {
    border-bottom: 4px solid grey;
    margin-bottom: 10px;
    .title {
      font-size: 35px;
    }
    .subtitle {
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }
  .text {
    color: #666666;
    text-decoration: underline;
  }
  .label {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    margin-top: 10px;
    background-position: center;
    background-size: contain;
  }
  .info {
    padding-left: 35px;
  }
  &.document {
    .label {
      background-image: url('./assets/document_fold.png')
    }
    .header {
      border-color: #1aa6b7;
    }

  }
  &.document-plus {
    .label {
      background-image: url('./assets/document_plus.png')
    }
    .header {
      border-color: #2c52c3;
    }

  }
  &.paperplane {
    .label {
      background-image: url('./assets/paperplane_outlined.png')
    }
    .header {
      border-color: #e6a722;
    }

  }
  &.coins {
    .label {
      background-image: url('./assets/coins.png')
    }
    .header {
      border-color: #b5845b;
    }

  }
  &.money {
    .label {
      background-image: url('./assets/money.png')
    }
    .header {
      border-color: #67ba2f;
    }

  }
  &.wallet {
    .label {
      background-image: url('./assets/wallet.png')
    }
    .header {
      border-color: #e67e22;
    }
  }
  &.cup {
    .label {
      background-image: url('./assets/cup.png')
    }
    .header {
      border-color: #76cad4;
    }
  }
}
