.advance-payment-table {
  .advance-payment-right {
    text-align: right;
  }
  label {
    font-weight: 300;
  }
  input[type='checkbox'] {
    width:25px;
    height:25px;
    border:2px solid #555;
  }
}