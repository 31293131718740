label.gray {
  font-weight: 200;
}
.modal-dialog {
  .form-control {
    background-color: #f2f6fa;
    &.wh {
      color: #4c637b;
      font-weight: 600;
      .pull-right {
        font-size: 24px;
      }
    }
  }
  div.form-control {
    margin-top: 24px
  }
  .receipts-container {
    border: 1px solid #ccc;
    background-color: #f2f6fa;
    border-radius: 5px;
    table {
      tr, thead {
        td {
          padding: 5px;
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
        }
        &:nth-child(even) {background: #e9f0f8}
      }
      thead {
        background-color: #e0e6ed;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.nomargin {
  margin: 0!important;
}

.progress-containter{
  margin-top: 15px;

  .progress-outer{
    background-color: #f2f2f2;
    border: thin solid #ccc;
    border-radius: 5px;
    position: relative;
    .progress-inner{
      height: 100%;
      position: absolute;
      background-color: rgba(0, 177, 230, 0.37);
      border-radius: 5px;
      -webkit-transition: width .2s ease-in-out, background-color .5s linear;
         -moz-transition: width .2s ease-in-out, background-color .5s linear;
           -o-transition: width .2s ease-in-out, background-color .5s linear;
              transition: width .2s ease-in-out, background-color .5s linear;
      &[style="width: 0%;"] {
        background-color: rgba(152, 152, 152, 0.37);
      }
      &[style="width: 100%;"] {
        background-color: rgba(73, 230, 0, 0.37);
      }
    }
    .progress-text{
      width: 100%;
      height: 100%;
      font-size: 15px;
      font-weight: 100;
      text-align: center;
      overflow: hidden;
      .progress-value{
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}
input.rc-time-picker-input {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px;
  border: 1px solid #ccc;
}
a.rc-time-picker-panel-clear-btn {
  display: none;
}
.rc-time-picker-panel-select {
  overflow-x: hidden;
}

.modal {
  select.form-control {
    font-size: 18px;
  }
  div.form-control {
  }
  .no-padding-top {
    padding-top: 0px;
  }
}
