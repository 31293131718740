@import 'base';

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 1280px;
$screen-lg: 1680px;
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import './bootstrap-theme.css';
@import './bootstrap.css';
@import './tailwind.css';


$linkcolour: #1aa6b7;

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
  touch-action: manipulation;
}

input,
button,
textarea,
select {
  //-webkit-transform: translate3d(0, 0, 0);
  //transform: translate3d(0, 0, 0);
}

html,
body {
  width: 100%;
  min-width: 480px;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

select.input-lg {
  line-height: 25px;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Black.eot');
  src: url('../fonts/SourceSansPro-Black.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-Black.woff2') format('woff2'),
  url('../fonts/SourceSansPro-Black.woff') format('woff'),
  url('../fonts/SourceSansPro-Black.ttf') format('truetype'),
  url('../fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Bold.eot');
  src: url('../fonts/SourceSansPro-Bold.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
  url('../fonts/SourceSansPro-Bold.woff') format('woff'),
  url('../fonts/SourceSansPro-Bold.ttf') format('truetype'),
  url('../fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-ExtraLightIt.eot');
  src: url('../fonts/SourceSansPro-ExtraLightIt.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-ExtraLightIt.woff2') format('woff2'),
  url('../fonts/SourceSansPro-ExtraLightIt.woff') format('woff'),
  url('../fonts/SourceSansPro-ExtraLightIt.ttf') format('truetype'),
  url('../fonts/SourceSansPro-ExtraLightIt.svg#SourceSansPro-ExtraLightIt') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-LightIt.eot');
  src: url('../fonts/SourceSansPro-LightIt.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-LightIt.woff2') format('woff2'),
  url('../fonts/SourceSansPro-LightIt.woff') format('woff'),
  url('../fonts/SourceSansPro-LightIt.ttf') format('truetype'),
  url('../fonts/SourceSansPro-LightIt.svg#SourceSansPro-LightIt') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Semibold.eot');
  src: url('../fonts/SourceSansPro-Semibold.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-Semibold.woff2') format('woff2'),
  url('../fonts/SourceSansPro-Semibold.woff') format('woff'),
  url('../fonts/SourceSansPro-Semibold.ttf') format('truetype'),
  url('../fonts/SourceSansPro-Semibold.svg#SourceSansPro-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-BlackIt.eot');
  src: url('../fonts/SourceSansPro-BlackIt.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-BlackIt.woff2') format('woff2'),
  url('../fonts/SourceSansPro-BlackIt.woff') format('woff'),
  url('../fonts/SourceSansPro-BlackIt.ttf') format('truetype'),
  url('../fonts/SourceSansPro-BlackIt.svg#SourceSansPro-BlackIt') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Light.eot');
  src: url('../fonts/SourceSansPro-Light.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
  url('../fonts/SourceSansPro-Light.woff') format('woff'),
  url('../fonts/SourceSansPro-Light.ttf') format('truetype'),
  url('../fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-BoldIt.eot');
  src: url('../fonts/SourceSansPro-BoldIt.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-BoldIt.woff2') format('woff2'),
  url('../fonts/SourceSansPro-BoldIt.woff') format('woff'),
  url('../fonts/SourceSansPro-BoldIt.ttf') format('truetype'),
  url('../fonts/SourceSansPro-BoldIt.svg#SourceSansPro-BoldIt') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Regular.eot');
  src: url('../fonts/SourceSansPro-Regular.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
  url('../fonts/SourceSansPro-Regular.woff') format('woff'),
  url('../fonts/SourceSansPro-Regular.ttf') format('truetype'),
  url('../fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-ExtraLight.eot');
  src: url('../fonts/SourceSansPro-ExtraLight.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
  url('../fonts/SourceSansPro-ExtraLight.woff') format('woff'),
  url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
  url('../fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-It.eot');
  src: url('../fonts/SourceSansPro-It.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-It.woff2') format('woff2'),
  url('../fonts/SourceSansPro-It.woff') format('woff'),
  url('../fonts/SourceSansPro-It.ttf') format('truetype'),
  url('../fonts/SourceSansPro-It.svg#SourceSansPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiboldIt.eot');
  src: url('../fonts/SourceSansPro-SemiboldIt.eot?') format('embedded-opentype'),
  url('../fonts/SourceSansPro-SemiboldIt.woff2') format('woff2'),
  url('../fonts/SourceSansPro-SemiboldIt.woff') format('woff'),
  url('../fonts/SourceSansPro-SemiboldIt.ttf') format('truetype'),
  url('../fonts/SourceSansPro-SemiboldIt.svg#SourceSansPro-SemiboldIt') format('svg');
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  text-rendering: optimizeLegibility;
}

input {
  color: #4c637b !important;
}

input[type='date'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input[type='time'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

a {
  color: #1aa6b7;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

#wrap {
  min-height: 100vh;
  height: auto !important;
  margin: 0 auto -60px;
}

.sidebar .container {
  @media (min-width: 1280px) {
    width: 1000px;
  }
  @media (max-width: 1279px) {
    margin-top: -30px;
  }
}

.confines {
  max-width: 830px;
}

.sidebar {
  .container {
    width: auto;
  }
}

.modal-open .container-fluid,
.modal-open .container {
  border: 1px;
}

.navbar-default {
  background-color: #4c637b;
  height: 80px;

  .navbar-brand {
    color: #fff;
    line-height: 50px;
    height: 80px;
    background-size: contain;
    margin-left: 0 !important;
  }

  &.navbar-main {
    background-color: #e3eaf3;
  }

  .navbar-nav {
    li {
      > * {
        line-height: 50px;
        padding: 15px;
        display: block;
      }

      a {
        text-decoration: underline;
        color: #fff;
      }
    }
  }
}

.modal {
  .valign-helper {
    width: inherit;
    height: inherit;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    position: relative;
    background-color: white;
    pointer-events: all;
  }

  .modal-header {
    padding: 15px;
    height: 70px;
    @media (min-width: 768px) {
      padding: 25px;
    }
    padding-left: 20px;
    background-color: #e3eaf3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .modal-body {
    padding: 20px;
  }

  .message {
    text-align: center;
    font-size: larger;
  }

  .close {
    margin-top: -25px;
  }
}

.margin-bottom {
  margin-bottom: 25px;
}

.modal-backdrop {
  background-color: #000;
  opacity: 0.5;
  z-index: 1001;
}

.navbar-default {
  background-image: none;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 0.8rem;
  width: 1.25rem;
  height: 1.25rem;
}

.form-check-label {
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.form-check-input {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.big-checkbox {
  width: 5vw;
  height: 5vh;
}

@media only screen and (min-width: 768px) {
  .modal-90w {
    width: 90%;
    max-width: 1280px;

    .modal-content {
      .modal-body {
      }
    }
  }
}
.mobile-date {
  font-weight: bold;
  font-size: 18px;
}
.modal-90w {

  button.close  {
    scale:2;
  }
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.glyphicon {
  color: #363636;
}

.top-10 {
  top: 10px;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: darken($color: $linkcolour, $amount: 10);
  }
}
