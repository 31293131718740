.ext-card {
   background-color:#f2f6fa;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  overflow: auto;
  padding: 20px;
  padding-bottom: 25px;
  ul{
    display: grid;
    li{
      width: 100%;
      margin-top:15px;
      > span{
        width: 50%;
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: flex-end;
  }
  &:hover{
    border-left: 5px solid #e6a722;
    padding-left: 10px;
    @media (max-width: 1279px){
      padding-left: 15px;
    }
  }
  .card-header{
    .title{
      color: #1aa6b7;
      font-size: 15px;
      font-weight: 600;
      display: table-cell;
    }

  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    .title{
      float: right;
      font-size: 15px;
      font-weight: 600;
    }
    .text{
      float: left;
    }
  }


  .btn {
    // border: 1px solid #1aa6b7;
    // border-radius:4px ;
    // height: 40px;
    // width: 200px;
    // line-height: unset;
    // padding: 0px;
    // background-color: transparent;
    // color: #1aa6b7;
    // line-height: 40px;
    // font-size: 13px;
    // font-weight:600;
  }
  .green {
    color: #67ba2f;
  }
  .red {
    color: #c13155;
  }
  .yellow {
    color: #e6a722;
  }
  .paid-mark {
    height: 20px;
    width: 20px;
    background-image: url('./assets/check.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle
  }
}


.card {
  background-color:#f2f6fa;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  overflow: auto;
  .btn-group {
    display: flex;
    justify-content: flex-end;
  }
  &:hover{
    border-left: 5px solid #e6a722;
    padding-left: 10px;
    @media (max-width: 1279px){
      padding-left: 15px;
    }
  }
  .card-header{
    .title{
      color: #1aa6b7;
      font-size: 15px;
      font-weight: 600;
      display: table-cell;
    }

  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    .title{
      float: right;
      font-size: 15px;
      font-weight: 600;
    }
    .text{
      float: left;
    }
  }
  @media (max-width: 1279px){
    padding: 20px;
    padding-bottom: 25px;
    ul{
      display: grid;
      li{
        width: 100%;
        margin-top:15px;
        > span{
          width: 50%;
        }
      }
    }
  }
  @media (min-width: 1280px){
    min-height: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
    .card-header{
      float: left;
      padding-left: 15px;
    }
    ul{
      width: 60%;
      display: flex;
      margin-right: 15px;
      float:right;
      li{
        width: 25%;
        .title {
          float:none;
          font-size: 18px;
          display: flex;
        }
      }
    }
  }
  .btn {
    // border: 1px solid #1aa6b7;
    // border-radius:4px ;
    // height: 40px;
    // width: 200px;
    // line-height: unset;
    // padding: 0px;
    // background-color: transparent;
    // color: #1aa6b7;
    // line-height: 40px;
    // font-size: 13px;
    // font-weight:600;
  }
  .green {
    color: #67ba2f;
  }
  .red {
    color: #c13155;
  }
  .yellow {
    color: #e6a722;
  }
  .paid-mark {
    height: 20px;
    width: 20px;
    background-image: url('./assets/check.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle
  }
}

.btn-group .btn, .btn.btn-square{
  -webkit-transition: border-color .2s linear, background-color .2s linear;
     -moz-transition: border-color .2s linear, background-color .2s linear;
       -o-transition: border-color .2s linear, background-color .2s linear;
          transition: border-color .2s linear, background-color .2s linear;
  &:hover{
    border-color: #b5bdc6;
    background-color: #d2dae4;
  }
  &:focus{
    outline:none;
  }
}
