.main-content{
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
aside.sidebar{
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  height: 100%;
  overflow: hidden;
  background-color: #4c637b;
  .menu-text{
    font-size: 16px;
  }
  &.opened {
    width: 250px;
  }
  &.minimized {
    width: 75px;
  }
  &.shrinked {
    height: 80px;
  }
  .nav li a,li a{
    color: #cccccc;
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    padding-top: 0 ;
    padding-bottom: 0;
    &:hover {
      background-color: #344c67;
    }
    &:focus {
      background-color: #344c67;
    }
    .icon-account{
      background-image: url("~./assets/inactive/account.png");
    }
    .icon-archive{
      background-image: url("~./assets/inactive/archive.png");
    }
    .icon-calculator{
      background-image: url("~./assets/inactive/calculator.png");
    }
    .icon-clients{
      background-image: url("~./assets/inactive/clients.png");
    }
    .icon-document{
      background-image: url("~./assets/inactive/document.png");
    }
    .icon-heart{
      background-image: url("~./assets/inactive/heart.png");
    }
    .icon-home{
      background-image: url("~./assets/inactive/home.png");
    }
    .icon-wallet{
      background-image: url("~./assets/inactive/wallet.png");
    }
    &.route--active{
      color: white;
      background-color: #1aa6b7;
      &:hover {
        background-color: #1aa6b7;
      }
      &:focus {
        background-color: #1aa6b7;
      }
      .icon-account{
        background-image: url("~./assets/active/account.png");
      }
      .icon-archive{
        background-image: url("~./assets/active/archive.png");
      }
      .icon-calculator{
        background-image: url("~./assets/active/calculator.png");
      }
      .icon-clients{
        background-image: url("~./assets/active/clients.png");
      }
      .icon-document{
        background-image: url("~./assets/active/document.png");
      }
      .icon-heart{
        background-image: url("~./assets/active/heart.png");
      }
      .icon-home{
        background-image: url("~./assets/active/home.png");
      }
      .icon-wallet{
        background-image: url("~./assets/active/wallet.png");
      }
    }
  }
  i {
    height: 20px;
    width: 20px;
    background-size: cover;
    float: left;
    margin-top: 20px;
  }
  .menu-text{
    margin-left: 30px;
  }
  .header {
    width: 100%;
    background-color: #344c67;
    height: 80px;
    float: none;
    a{
      height: 80px;
      float: left;
    }
    .sidebar-toggle{
      cursor: pointer;
      float: right;
      width: 15px;
      height: 80px;
      background-size: 100%;
      background-position: right;
      background-repeat: no-repeat;
    }
  }
}
.sidebar-opened{
  .sidebar{
    width: 250px;
    @media (min-width: 1680px){
      width: 300px;
    }
    .header,li a {
      padding-left: 30px;
      padding-right: 30px;
      @media (min-width: 1680px){
        padding-left: 50px;
        &.sidebar{
          width: 300px;
        }
      }
    }
  }
}
.side-footer{
  background-color: #405771;
  bottom: 0;
  width: 100%;
  position: absolute;
  .icon-question{
    background-image: url("~./assets/question.png");
  }
}
.sidebar-minimized{
  aside.sidebar{
    width: 75px;
    .ninjou-logo, .menu-text{
      display: none;
    }
    .sidebar-toggle{
      width: 100%;
      background-size: 25%;
      background-position: center;
    }
    i{
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    @media (max-width: 1279px){
      height:80px;
      .side-footer{
        display: none;
      }
    }
  }
}
