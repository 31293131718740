.core-layout__viewport {
  padding-top: 6rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 3rem;
  margin-bottom: 6rem;
  @media (min-width: 768px){
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 1280px){
    padding-bottom: 6rem;
  }
}
.sidebar {
  .core-layout__viewport {
    padding-top: 2rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1280px){
  .sidebar-opened {
    .main-content {
      margin-left: 250px;
      position: relative;
    }
  }
  .sidebar-minimized{
    .main-content{
      margin-left: 75px;
    }
  }
}
@media (min-width: 1680px){
  .sidebar-opened {
    .main-content {
      margin-left: 300px;
      position: relative;
    }
  }
}
